import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import logo from './tbw-logo.png';

const Navigation = () => {
    return (
        <>
            <Link to="/"><img src={logo} style={{maxInlineSize:"100%",blockSize:"auto",paddingLeft:"10px",paddingRight:"10px"}} alt="Logo" /></Link>
            <Navbar bg="light" expand="lg" className="bg-light justify-content-between">
                <Container>
                    <Navbar.Toggle className="menuShow" aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/"  >Home</Nav.Link>
                        <Nav.Link href="/ToS" >ToS</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};
export default Navigation