import React,{ChangeEvent, useState} from "react";
import AWS from "aws-sdk";
import "bootstrap/dist/css/bootstrap.min.css";
import subscribe from './subscribe.png';
import upload from './upload.png';
import choose from './choose.png';


const Home = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const S3_BUCKET = "tbwupload";
    const REGION = "us-east-1";
    AWS.config.update({
        accessKeyId: "AKIAS2GPBO4JH6GMIFRB",
        secretAccessKey: "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"
    });



    const myBucket = new AWS.S3({
        params: { Bucket: S3_BUCKET},
        region: REGION,
    });


    const [tosmsg, settosmsg] = useState("Video max duration: 60 seconds");
    const date = new Date();
    const [listOfFiles, updatelistOfFiles] = useState<{Filename: string | null;}[]>([]);
    let nextId = 0;
    const [selectedFile, setSelectedFile] = useState<FileList | null>(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
        updatelistOfFiles([]);
        const MAX_DURATION_SIZE = 60;
        if (!e.target.files) {
            return;
        }
        
        for (let i = 0; i < e.target.files.length; i++) {
            const videoEl = document.createElement("video");
            videoEl.src = window.URL.createObjectURL(e.target.files[i]);
            videoEl.onloadedmetadata = event => {
                if (!e.target.files) {
                    return;
                }
                const { name } = e.target.files[i];
                const { duration } = videoEl;
                if(videoEl.duration > MAX_DURATION_SIZE){
                    setErrorMsg(`Video Filename: ${name} is longer than ${MAX_DURATION_SIZE} seconds`);
                    alert(`Video Filename: ${name} is longer than ${MAX_DURATION_SIZE} seconds`);
                    setIsSuccess(false);
                    window.URL.revokeObjectURL(videoEl.src);
                    return;
                };
                updatelistOfFiles(prevlistOfFile => [...prevlistOfFile,{Filename: `${name}`}]);
                window.URL.revokeObjectURL(videoEl.src);
            };
            
        };
        setErrorMsg("");
        setIsSuccess(true);
        setSelectedFile(e.target.files);
    };
    const [fname, setfname] = useState(" ");
    const handlefnameInput = (f: ChangeEvent<HTMLInputElement>) => {
        setfname(f.target.value);
    };
    const [city, setcity] = useState(" ");
    const handlecityInput = (g: ChangeEvent<HTMLInputElement>) => {
        setcity(g.target.value);
    };
    const [state, setstate] = useState(" ");
    const handlestateInput = (h: ChangeEvent<HTMLInputElement>) => {
        setstate(h.target.value);
    };
    const [bat, setbat] = useState(" ");
    const handlebatInput = (j: ChangeEvent<HTMLInputElement>) => {
        setbat(j.target.value);
    };
    const [age, setage] = useState("");
    const handleageInput = (j: ChangeEvent<HTMLInputElement>) => {
        setage(j.target.value);
    };
    const [tos, settos] = useState(false);
    const handletosInput = (k: ChangeEvent<HTMLInputElement>) => {
        settos(k.target.checked);
    };
    const uploadFile = () => {
    if (!selectedFile) {
            
        setErrorMsg("Please choose a file");
        return;
    }
    if (!age) {
            
        setErrorMsg("Age Field is Required");
        return;
    }
    if (!tos && selectedFile) {
        settosmsg('Please agree to Terms of Service to Upload.');
        return;
    }
    if (tos && isSuccess) {
        settosmsg('');
        if (fname.length>0 || city.length >0 || state.length >0 || age.length >0 || bat.length >0){
            const fileInfo = new File([fname.toString() + "," + city.toString() + "," + state.toString() + "," + age.toString() + "," + bat.toString()], date.toISOString() + "-" + "video-fileinfo.csv", {
                type: "text/plain",
                });
                const params = {
                Body: fileInfo,
                Bucket: S3_BUCKET,
                Key: fileInfo.name
                };
                myBucket.putObject(params)
                    .send((err) => {
                         if (err) console.log(err)
                    })
            }
            
        var newProgress = 0;
        for (let i = 0; i < selectedFile.length; i++) {
            var file = selectedFile[i];
            const params = {
                Body: file,
                Bucket: S3_BUCKET,
                Key: date.toISOString() + "-" + `video-file${i}` + "." + file.name.split('.').pop()
            };
            myBucket.putObject(params)
                .on('httpUploadProgress', (evt) => {
                    var newArr = [0];
                    newArr[i] = (Math.round((evt.loaded / evt.total) * 100));
                    if((Math.round((evt.loaded / evt.total) * 100))===100){
                            settosmsg('Upload Complete');
                    }
                    else{
                        settosmsg('Uploading');
                    }
                }).send((err) => {
                    if (err) console.log(err)
                })
            };  
    } 
    return;
    };
    return (
        <>
        
        
        <div className="container"id="mainContainer">
        <div  style={{display:isModalVisible ? "block" : "none"}}  id="overlay" onClick={() => setIsModalVisible(false)}>
        <div style={{background:"#fff",padding:"10px",textAlign:"left"}}>
                    
                    
                        <p>
                            <strong>
                                <span>
                                    TRAVEL BALL WEEKLY - TERMS AND CONDITIONS FOR VOLUNTARY VIDEO UPLOADS
                                </span>
                            </strong>
                            <span>
                            </span>
                        </p>
                        <p>
                            <strong>
                                <span>
                                    Last Updated: 08/12/2023
                                </span>
                            </strong>
                            <span>
                            </span>
                        </p>
                        <p>
                            <span>
                                These terms and conditions (&quot;Terms&quot;) govern your voluntary submission of personal videos (&quot;User Content&quot;) to Travel Ball Weekly (&quot;Travel Ball Weekly,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) for the purpose of creating compilation videos and posting them on our Social Media channels. By submitting User Content to Travel Ball Weekly, you agree to the following terms:
                            </span>
                        </p>
                    
                    
                        <p>1.
                            <span>
                                <span>
                                    &nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                            <strong>
                                <span>
                                    User Content License and Release:
                                </span>
                            </strong>
                            <br></br>
                            <span>
                                By voluntarily uploading and submitting User Content to Travel Ball Weekly, you grant Travel Ball Weekly a non-exclusive, worldwide, royalty-free, perpetual, and irrevocable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Content for promotional and marketing purposes. You acknowledge that you have no expectation of compensation, credit, or ownership rights for the use of the User Content.
                            </span>
                        </p>
                        <p>2.
                            <span>
                                <span>
                                    &nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                            <strong>
                                <span>
                                    User Representations and Warranties:
                                </span>
                            </strong>
                            <br></br>
                            <span>
                                You represent and warrant that you have the legal right and authority to submit the User Content to Travel Ball Weekly, and that the User Content does not infringe upon the intellectual property, privacy, or other rights of any third party. You further represent that any individuals appearing in the User Content have given their consent for their likeness to be used as described in these Terms.
                            </span>
                        </p>
                        <p>3.
                            <span>
                                <span>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                            </span>
                            <strong>
                                <span>
                                    Release of Liability:
                                </span>
                            </strong>
                            <br></br>
                            <span>
                                By submitting User Content, you release Travel Ball Weekly, its officers, directors, employees, agents, affiliates, and partners from
                                <span>
                                    any and all
                                </span>
                                liability arising out of or in connection with the use, reproduction, distribution, display, or performance of the User Content, including any claims for defamation, invasion of privacy, copyright infringement, or other intellectual property claims.
                            </span>
                        </p>
                        <p>4.
                            <span>
                                <span>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                            </span>
                            <strong>
                                <span>
                                    Indemnification:
                                </span>
                            </strong>
                            <br></br>
                            <span>You agree to indemnify and hold Travel Ball Weekly harmless from and against
                                <span>
                                    any and all
                                </span>
                            claims, liabilities, damages, losses, and expenses (including legal fees) arising out of or in connection with your breach of these Terms, your submission of User Content, or your violation of any law or the rights of any third party.
                            </span>
                        </p>
                        <p>5.
                            <span>
                                <span>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                            </span>
                            <strong>
                                <span>
                                    Modification and Termination:
                                </span>
                            </strong>
                            <br></br>
                            <span>Travel Ball Weekly reserves the right to modify, suspend, or terminate this voluntary video upload program at any time, without notice. We may also remove or refuse to use any User Content for any reason at our sole discretion.
                            </span>
                        </p>
                        <p>6.
                            <span>
                                <span>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                            </span>
                            <strong>
                                <span>
                                    Governing Law and Jurisdiction:
                                </span>
                            </strong>
                            <br></br>
                            <span>
                                These Terms shall be governed by and construed in accordance with the laws of Tennessee, without regard to its conflicts of law principles. Any disputes arising from or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in Tennessee.
                            </span>
                        </p>
                
                    <p>
                        <span>
                            By submitting User Content to Travel Ball Weekly, you acknowledge that you have read, understood, and agree to abide by these Terms. If you do not agree with these Terms, please do not submit any User Content to Travel Ball Weekly.
                        </span>
                    </p>
                    <p>
                        <span>
                            If you have any questions about these Terms or the use of User Content, please contact us at tbw@mgaccess.net
                        </span>
                    </p>
                
                <p></p>
                
                
            </div>
        </div>
            <div id="FormWrapper">
                <div><br></br></div>
                <div><p>Welcome! To upload a video, click "Choose Video", and fill in the optional info if you would like it mentioned during the video, then UPLOAD. Thank you!</p></div>   
                <div><label htmlFor="fileinput"><img src={choose} style={{maxInlineSize:"100%",blockSize:"auto"}} alt="choose" /></label></div>
                <div><input id="fileinput" style={{visibility:"hidden"}} type={"file"} accept="video/mp4,video/x-m4v,video/*" onChange={handleFileInput} multiple/></div>
                <ul id="FileList">
                    {listOfFiles.map((File, index) => (
                    <li key={index}>{File.Filename}</li>
                    ))}
                </ul>
                <div>{tosmsg}</div>
                <div><p className="error-message">{errorMsg}</p></div>
                <div style={{textAlign:"left",height:"48px",fontWeight:"bold"}}><p>Only the AGE field is Required</p></div>
                <div><input type="text" id="fname" name="fname" placeholder="Players First Name (optional)" onChange={handlefnameInput}/></div>
                <div><span className="required-field"></span><input type="text" id="age" name="age" placeholder="Age In Video (REQUIRED)" onChange={handleageInput}/></div>
                <div><input type="text" id="city" name="city" placeholder="City (optional)" onChange={handlecityInput}/></div>
                <div><input type="text" id="state" name="state" placeholder="State (optional)" onChange={handlestateInput}/></div>
                <div><input type="text" id="bat" name="bat" placeholder="Bat Type (optional)" onChange={handlebatInput}/></div>
                <div><input type="checkbox" name="tos" id="tos" value="I Agree to Terms of Service." onChange={handletosInput}/> I Agree to <a href="#" onClick={() => setIsModalVisible(true)} >Terms of Service</a>.</div>
                <div id="uploadbttn"><button onClick={() => uploadFile()}><img src={upload} style={{maxInlineSize:"100%",blockSize:"auto"}} alt="subscribe" /></button></div>
            </div>
            
            <div id="footer">
                <div>Quick link to subscribe to the channel <a href="https://youtube.com/@travelballweekly?sub_confirmation=1">@TravelBallWeekly</a></div>
                <div><a href="https://youtube.com/@travelballweekly?sub_confirmation=1"><img src={subscribe} style={{maxInlineSize:"100%",blockSize:"auto"}} alt="subscribe" /></a></div>
            </div>
        </div>
        </>
    );
};

export default Home;