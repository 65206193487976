import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Home';
import Navigation from './components/Navigation';
import ToS from './components/ToS';


function App () {
    return (        
        <>
        <div className="App">
            <div id="MainContainer">
                <Navigation/>
                <Routes>
                    <Route path='ToS' element={<ToS/>}/>
                    <Route path='/' element={<Home/>}/>            
                </Routes>
            </div>
        </div>      
        </>
    );
    
};

export default App;