import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ToS = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container id="ToS" style={{textAlign:"left"}}>
            <Row>
                <Col>
                <button onClick={() => navigate('/')}>Go back</button>
                <div >
                    <div>
                    
                        <p>
                            <strong>
                                <span>
                                    TRAVEL BALL WEEKLY - TERMS AND CONDITIONS FOR VOLUNTARY VIDEO UPLOADS
                                </span>
                            </strong>
                            <span>
                            </span>
                        </p>
                        <p>
                            <strong>
                                <span>
                                    Last Updated: 08/12/2023
                                </span>
                            </strong>
                            <span>
                            </span>
                        </p>
                        <p>
                            <span>
                                These terms and conditions (&quot;Terms&quot;) govern your voluntary submission of personal videos (&quot;User Content&quot;) to Travel Ball Weekly (&quot;Travel Ball Weekly,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) for the purpose of creating compilation videos and posting them on our Social Media channels. By submitting User Content to Travel Ball Weekly, you agree to the following terms:
                            </span>
                        </p>
                    </div>
                    <div>
                        <p>1.
                            <span>
                                <span>
                                    &nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                            <strong>
                                <span>
                                    User Content License and Release:
                                </span>
                            </strong>
                            <br></br>
                            <span>
                                By voluntarily uploading and submitting User Content to Travel Ball Weekly, you grant Travel Ball Weekly a non-exclusive, worldwide, royalty-free, perpetual, and irrevocable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Content for promotional and marketing purposes. You acknowledge that you have no expectation of compensation, credit, or ownership rights for the use of the User Content.
                            </span>
                        </p>
                        <p>2.
                            <span>
                                <span>
                                    &nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                            <strong>
                                <span>
                                    User Representations and Warranties:
                                </span>
                            </strong>
                            <br></br>
                            <span>
                                You represent and warrant that you have the legal right and authority to submit the User Content to Travel Ball Weekly, and that the User Content does not infringe upon the intellectual property, privacy, or other rights of any third party. You further represent that any individuals appearing in the User Content have given their consent for their likeness to be used as described in these Terms.
                            </span>
                        </p>
                        <p>3.
                            <span>
                                <span>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                            </span>
                            <strong>
                                <span>
                                    Release of Liability:
                                </span>
                            </strong>
                            <br></br>
                            <span>
                                By submitting User Content, you release Travel Ball Weekly, its officers, directors, employees, agents, affiliates, and partners from
                                <span>
                                    any and all
                                </span>
                                liability arising out of or in connection with the use, reproduction, distribution, display, or performance of the User Content, including any claims for defamation, invasion of privacy, copyright infringement, or other intellectual property claims.
                            </span>
                        </p>
                        <p>4.
                            <span>
                                <span>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                            </span>
                            <strong>
                                <span>
                                    Indemnification:
                                </span>
                            </strong>
                            <br></br>
                            <span>You agree to indemnify and hold Travel Ball Weekly harmless from and against
                                <span>
                                    any and all
                                </span>
                            claims, liabilities, damages, losses, and expenses (including legal fees) arising out of or in connection with your breach of these Terms, your submission of User Content, or your violation of any law or the rights of any third party.
                            </span>
                        </p>
                        <p>5.
                            <span>
                                <span>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                            </span>
                            <strong>
                                <span>
                                    Modification and Termination:
                                </span>
                            </strong>
                            <br></br>
                            <span>Travel Ball Weekly reserves the right to modify, suspend, or terminate this voluntary video upload program at any time, without notice. We may also remove or refuse to use any User Content for any reason at our sole discretion.
                            </span>
                        </p>
                        <p>6.
                            <span>
                                <span>
                                    <span>
                                        &nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                            </span>
                            <strong>
                                <span>
                                    Governing Law and Jurisdiction:
                                </span>
                            </strong>
                            <br></br>
                            <span>
                                These Terms shall be governed by and construed in accordance with the laws of Tennessee, without regard to its conflicts of law principles. Any disputes arising from or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in Tennessee.
                            </span>
                        </p>
                </div>
                <div>
                    <p>
                        <span>
                            By submitting User Content to Travel Ball Weekly, you acknowledge that you have read, understood, and agree to abide by these Terms. If you do not agree with these Terms, please do not submit any User Content to Travel Ball Weekly.
                        </span>
                    </p>
                    <p>
                        <span>
                            If you have any questions about these Terms or the use of User Content, please contact us at tbw@mgaccess.net
                        </span>
                    </p>
                </div>
                <p></p>
                
                
            </div>
            <button onClick={() => navigate('/')}>Go back</button>
                </Col>
            </Row>
        </Container>
        
        </>
        
    );
};
export default ToS;